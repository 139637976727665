import React, {Component} from 'react';
import {Home} from "./pages";
import './styles/global.scss';

class App extends Component {
  render() {
    return <Home/>;
  }
}

export default App;
